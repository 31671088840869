// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headerMenu > button {
    background-color: transparent;
    font-size: 1.2rem;
    border: none;
    color: white;
}

.headerMenu > button:hover {
    font-weight: bold;
    border-bottom: 0.1rem solid white;
}`, "",{"version":3,"sources":["webpack://./src/Components/Components.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,iBAAiB;IACjB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,iCAAiC;AACrC","sourcesContent":[".headerMenu > button {\n    background-color: transparent;\n    font-size: 1.2rem;\n    border: none;\n    color: white;\n}\n\n.headerMenu > button:hover {\n    font-weight: bold;\n    border-bottom: 0.1rem solid white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
