const themeBackground = '#fff';
const themeHighlight = '#040b43';
const title = 'votecounter';
const BACKEND_URL = 'http://api.codebrew.caillin.net/api'

export {
    themeBackground,
    themeHighlight,
    title,
    BACKEND_URL
}